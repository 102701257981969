import React from 'react';

import Layout from 'components/layout';
import SEO from 'components/seo';
import { Global } from '@emotion/core';
import SideBar from '../../sections/blog/SideBar';
import Article from '../../sections/blog/Article';
import { graphql } from 'gatsby';
import { injectIntl, IntlContextConsumer } from 'gatsby-plugin-intl';
import Helmet from 'react-helmet';

const LoyaltyCampaigns = ({ data, intl, ...rest }) => {
  let description = data.markdownRemark.html.replace(/<[^>]+>/g, '').slice(0, 155);
  const lastSpace = description.lastIndexOf(' ');
  description = description.slice(0, lastSpace);
  description += `…`

  return (
    <>
      <Global
        styles={theme => ({
          body: {
            fontFamily: theme.fonts.body,
          }
        })}
      />
      <Layout>
        <IntlContextConsumer>
          {({ languages, language: currentLocale }) => (
            <SEO
              title={data.markdownRemark.frontmatter.title}
              lang={currentLocale}
              description={description}
            />
          )}
        </IntlContextConsumer>
        <IntlContextConsumer>
          {({ languages, language: currentLocale }) => (
            <Helmet>
              <link rel="alternate" hrefLang="x-default" href="https://couponise.app/blog/loyalty-campaigns/" />
              {languages.map(language => {
                if(language !== intl.defaultLocale) {
                  return (
                    <link key={language} rel="alternate" hrefLang={`${language}`} href={`https://couponise.app/${language}/blog/loyalty-campaigns/`} />
                  )
                }
              })}
            </Helmet>
          )}
        </IntlContextConsumer>
        <div className="container pt-23 pt-md-26 pt-lg-31 pb-13 pb-md-18 pb-lg-23">
          <div className="row justify-content-center">
            <Article html={data.markdownRemark.html} title={data.markdownRemark.frontmatter.title} />
            <SideBar />
          </div>
        </div>
      </Layout>
    </>
  )
};

export default injectIntl(LoyaltyCampaigns);

export const pageQuery = graphql`
  query($path: String) {
    markdownRemark(frontmatter: {slug: {eq: $path}}) {
      html
      frontmatter {
        slug
        title
      }
    }
  }
`

